












import { Component, Vue, Prop } from 'vue-property-decorator';
import { Testimonial } from 'client-website-ts-library/types';

@Component
export default class TestimonialItem extends Vue {
  @Prop()
  private readonly testimonial!: Testimonial;
}
